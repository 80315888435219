import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import { withGA, eventPortfolioCreated, eventPortfolioDuplicated, eventPortfolioUpdated } from '../utils/googleAnalytics';

import Button from '../components/Button';
import ActionLink from '../components/ActionLink';
import InputField from '../components/InputField';
import ErrorBox from '../components/ErrorBox';
import Toast from '../components/Toast';
import EllipsesContainer from '../containers/EllipsesContainer';
import { ERR_EMPTY_PORTFOLIO_NAME, ERR_BASIC_PLAN_PORTFOLIO_LIMIT_REACHED } from '../constants';
import { createPortfolio, getPortfolios, setDefaultPortfolio, duplicatePortfolio, renamePortfolio } from '../parse';
class PortfolioSideModal extends Component {
  static propTypes = {
    activePortfolioId: PropTypes.string,
    setActivePortfolio: PropTypes.func.isRequired,
    openDeleteModal: PropTypes.func,
    portfolioToDelete: PropTypes.object,
    triggerGetAllPortfolios: PropTypes.bool,
    onBasicPlan: PropTypes.bool.isRequired,
    updateDefaultPortfolio: PropTypes.func.isRequired,
  }

  static defaultProps = {
    openDeleteModal: () => {},
    activePortfolioId: null,
    portfolioToDelete: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      portfolios: [],
      showNewInput: false,
      activeRenameItemId: null,
      errorMessage: null,
      toast: null,
      tdh: {},
    };
    this.portfolioName = '';
  }

  componentDidMount = async () => {
    this.props.onRef(this)
    this.initializeModal();
    this.getAllPortfolios();
  }

  async componentDidUpdate(prevProps) {
    const { portfolioToDelete: { id }, triggerGetAllPortfolios } = this.props;

    $('.tooltip').tooltip('hide');
    if (id !== prevProps.portfolioToDelete.id) {
      this.getAllPortfolios();
    }
    if (prevProps.triggerGetAllPortfolios !== triggerGetAllPortfolios) {
      this.getAllPortfolios();
    }
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  initializeModal = () => { // clears input field when modal closes
    const modal = $('#portfolioSideModal');
    modal.on('hidden.bs.modal', () => this.resetInputFields());
  }

  closeModal = (modalId) => {
    $(`${modalId}`).modal('hide');
  }

  getAllPortfolios = async () => {
    const portfolios = await getPortfolios();
    portfolios.sort((a, b) => { return b.isDefault - a.isDefault });
    await this.setState({ portfolios, showNewInput: false });
  }

  createNewPortfolio = async (e, name) => {
    e.preventDefault();

    const { portfolios, onBasicPlan } = this.props;

    if (!name) return this.setState({ errorMessage: ERR_EMPTY_PORTFOLIO_NAME });
    if (onBasicPlan && portfolios.length >= 1) {
      return this.setState({ errorMessage: ERR_BASIC_PLAN_PORTFOLIO_LIMIT_REACHED });
    };

    try {
      await createPortfolio(name);
    } catch (err) {
      this.setState({ errorMessage: err.message })
    }
    eventPortfolioCreated();
    this.getAllPortfolios();
    this.portfolioName = '';
  }

  toggleNewInputField = (bool) => {
    this.portfolioName = '';
    this.setState({ showNewInput: bool, activeRenameItemId: false });
  }

  setRename = (portfolioId) => {
    this.setState({ activeRenameItemId: portfolioId, showNewInput: false });
  }

  rename = async (e, portfolioId, name) => {
    const { setActivePortfolio, activePortfolioId } = this.props;
    $('[data-toggle="tooltip"]').tooltip('hide');
    e.preventDefault();
    if (!name) return this.setState({ errorMessage: ERR_EMPTY_PORTFOLIO_NAME });
    const newNamePortfolio = await renamePortfolio(portfolioId, name);
    if (activePortfolioId === portfolioId) {
      setActivePortfolio(newNamePortfolio, true);
    }
    await this.getAllPortfolios();

    this.setState({ activeRenameItemId: null });
    this.portfolioName = '';

    // google analytics
    eventPortfolioUpdated('Renamed');
  }

  makeDefault = async (portfolioId) => {
    const { updateDefaultPortfolio } = this.props;
    await setDefaultPortfolio(portfolioId);
    this.getAllPortfolios();
    const newDefault = await new Parse.Query('Portfolio').get(portfolioId);
    updateDefaultPortfolio(newDefault.toJSON());
  }

  duplicate = async (portfolioId) => {
    const { setActivePortfolio, getTransactions, tdh } = this.props;
    let newPortfolio;
    if (tdh.id === portfolioId) {
      const transactions = await getTransactions(tdh.id);
      newPortfolio = await duplicatePortfolio(portfolioId, Object.values(transactions));
    } else {
      newPortfolio = await duplicatePortfolio(portfolioId);
    }
    eventPortfolioDuplicated();
    this.getAllPortfolios();
    setActivePortfolio(newPortfolio);
  }

  openToast = (toastObject) => {
    this.setState({ toast: toastObject });
    setTimeout(() => this.hideToast(), 2500);
  }

  hideToast = () => {
    this.setState({ toast: null });
  }

  resetInputFields = () => {
    this.setState({
      showNewInput: false,
      activeRenameItemId: null
    })
  }

  redirectTo = (route) => {
    const { history } = this.props;
    this.closeModal('#portfolioSideModal');
    history.push(route);
  }

  render() {
// eslint-disable-next-line
    const { setActivePortfolio, activePortfolioId, openDeleteModal, onBasicPlan, tagIds, tdh } = this.props;
    const { portfolios, showNewInput, activeRenameItemId, errorMessage, toast } = this.state;
    const currentUser = Parse.User.current();
// eslint-disable-next-line
    const isTDH = (currentUser && tagIds) && (currentUser.attributes.plans.includes(tagIds.TDH) || currentUser.attributes.plans.includes(tagIds.BUNDLE_PLAN));
    return (
      <div>
        { onBasicPlan
          ? <h6 className="header-pretitle text-secondary">Used {portfolios.length} of 1</h6>
          : <h6 className="header-pretitle text-secondary">{portfolios.length} of Unlimited</h6>
        }
        <h2>Your Portfolios</h2>
        <hr/>
        { portfolios.map((portfolio) => {
          const { id, name, isDefault } = portfolio;
          if (id === activeRenameItemId) {
            return (
              <div key={id} className="btn btn-light mt-3">
                <div className="d-flex justify-content-between p-1">
                  <form
                    onSubmit={(e) => this.rename(e, id, this.portfolioName)}>
                    <InputField
                      id="rename-portfolio-input"
                      placeholder="Enter Portfolio Name"
                      defaultValue={name}
                      inputValue={(input) => { this.portfolioName = input; }}
                      type="text"
                      autoFocus
                    />
                  </form>
                  <div className="d-flex flex-row my-auto">
                    <div className="mx-1">
                      <Button
                        name=""
                        icon="fe fe-x"
                        clickAction={() => this.setRename()}
                        dashColor="primary btn-sm btn-outline-primary px-1 circle-button"
                        iconStyling="d-flex flex-start"
                      />
                    </div>
                    <div>
                      <Button
                        name=""
                        icon="fe fe-check"
                        clickAction={(e) => this.rename(e, id, this.portfolioName)}
                        dashColor="primary btn-sm btn-outline-primary px-1 circle-button"
                        iconStyling="d-flex flex-start"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div key={id} className="w-100 mb-2">
                { onBasicPlan ? (
                  <EllipsesContainer
                    key={id}
                    name={name}
                    dashColor={activePortfolioId === id ? 'dark' : 'white'}
                    ellipsesColor={activePortfolioId === id ? 'white' : 'dark'}
                    clickAction={() => setActivePortfolio(portfolio)}
                    icon={isDefault ? 'fe fe-star mr-3' : null}
                    dropDownItems={[
                      { name: 'Rename', clickAction: () => this.setRename(id) },
                    ]}
                  />
                ) : (
                  <EllipsesContainer
                    key={id}
                    name={name}
                    dashColor={activePortfolioId === id ? 'dark' : 'white'}
                    ellipsesColor={activePortfolioId === id ? 'white' : 'dark'}
                    clickAction={() => setActivePortfolio(portfolio)}
                    icon={isDefault ? 'fe fe-star mr-3' : null}
                    dropDownItems={[
                      { name: 'Rename', clickAction: () => this.setRename(id) },
                      { name: 'Make Default', clickAction: () => this.makeDefault(id) },
                      { name: 'Duplicate', clickAction: () => this.duplicate(id) },
                      { name: 'Delete', clickAction: portfolios.length > 1 ? () => openDeleteModal(portfolio) : () => this.openToast({ message: 'Must have at least one portfolio', type: 'error' }) },
                    ]}
                  />
                ) }
            </div>
          );
        })}
        { showNewInput ? (
          <div className="btn btn-light mt-3 d-flex justify-content-between p-1">
            <form onSubmit={e => this.createNewPortfolio(e, this.portfolioName)}>
              <InputField
                id="portfolioSideInput"
                placeholder="Enter Portfolio Name"
                inputValue={(input) => { this.portfolioName = input; }}
                type="text"
                autoFocus
              />
            </form>
            <div className="d-flex flex-row my-auto">
              <div className="mx-1">
                <Button
                  name=""
                  icon="fe fe-x"
                  clickAction={() => this.toggleNewInputField()}
                  dashColor="outline-primary btn-sm px-1 circle-button"
                  iconStyling="d-flex flex-start"
                />
              </div>
              <div>
                <Button
                  name=""
                  icon="fe fe-check"
                  clickAction={e => this.createNewPortfolio(e, this.portfolioName)}
                  dashColor="outline-primary btn-sm px-1 circle-button"
                  iconStyling="d-flex flex-start"
                />
              </div>
            </div>
          </div>)
          : null }

        {/* { onBasicPlan ? (
          <EllipsesContainer
            name={tdh ? tdh.name : 'The Dividend Hunter'}
            dashColor='light'
            clickAction={() => this.redirectTo('/portfolio/TDH')}
            ellipsesColor="dark"
            icon={isTDH ? '' : 'fe fe-lock mr-3'}
        />
        ) : (
          <EllipsesContainer
            name={tdh ? tdh.name : 'The Dividend Hunter'}
            dashColor='light'
            clickAction={() => this.redirectTo('/portfolio/TDH')}
            ellipsesColor="dark"
            icon={isTDH ? '' : 'fe fe-lock mr-3'}
            dropDownItems={[
              { name: 'Duplicate', clickAction: () => this.duplicate(tdh.id) },
            ]}
          />
        )} */}



        { onBasicPlan
            && (
              <div className="flex-fill fixed-bottom mb-7 mx-4">
                <ActionLink text="Upgrade to Premium" clickAction={() => this.redirectTo('/account/billing')}/> for Unlimited Portfolios
              </div>
            )
        }

        <div className="flex-fill fixed-bottom mb-7 mx-4">
          <ErrorBox errorMsg={errorMessage} />
        </div>

        <div className="modal-footer flex-fill fixed-bottom">
          <Button
            name="New Portfolio"
            icon="fe fe-plus-circle mr-3" dashClass="btn-info btn-block"
            clickAction={() => this.toggleNewInputField(true)}
            disabled={onBasicPlan && portfolios.length>=1}
          />
        </div>
        <Toast notification={toast} onClose={() => this.hideToast()} />
      </div>
    );
  }
}
export default withGA(withRouter(PortfolioSideModal));
