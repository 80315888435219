import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Parse from 'parse';
import $ from 'jquery';

import Account from './account/Account';
import Notifications from './account/AccountNotifications';
import PrivateRoute from '../components/PrivateRoute';
import ImportPage from './import/ImportPage';
import ChoosePassword from './main/ChoosePassword';
import Home from './main/Home';
import ResetPassword from './main/ResetPassword';
import Signin from './main/Signin';
import Support from './main/Support';
import { 
  RedirectFromResetPassword,
  RedirectFromVerifyEmail,
} from './main/RedirectFromResetPassword';
import NotFound from './NotFound';
import Onboarding from './onboarding/Onboarding';
import Plans from './Plans/Plans';
import Portfolio from './portfolio/Portfolio';
import TickerContent from './ticker/TickerContent'
import Reports from './reports/Reports';

import { serializer } from '../utils/sessionStorage';

const TOAST_DELAY = 5000;

class Main extends Component {
  static propTypes = {
    setProgressPercentage: PropTypes.func.isRequired,
    notifications: PropTypes.array,
    notificationsSeen: PropTypes.bool,
    setNotificationStatus: PropTypes.func,
    navPadding: PropTypes.number,
    updateUserRole: PropTypes.func.isRequired,
    onBasicPlan: PropTypes.bool.isRequired, 
    subscribedToTDH: PropTypes.bool.isRequired,
    onPremiumPlan: PropTypes.bool.isRequired, 
    checkUserPlans: PropTypes.func.isRequired,
  };

  static defaultProps = {
    notifications: [],
    notificationsSeen: false,
    navPadding: 0,
    setNotificationStatus: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      csvPreview: JSON.parse(sessionStorage.getItem('csvPreview')) || {},
      // toast notification
      notification: null,
    };
  }

  async componentDidMount() {
    if (Parse.User.current()) {
      this.checkInvalidToken();
    }
    
    // add event listener to save state to sessionStorage when user leaves/refreshes the page
    // state will reset when user exits browser/tab, hence the use of sessionStorage over localStorage
    window.addEventListener("beforeunload", this.saveStateToSessionStorage.bind(this));

    // state persistence
    await this.hydrateStateWithSessionStorage();
  }

  async checkInvalidToken() {
    const { history, userIsAdmin, checkUserPlans} = this.props;
    try {
      // make a simple call (isAdmin is the most simple function from cloud code)
      // this call will return INVALID_SESSION_TOKEN if user session is expired
      const isAdmin = await Parse.Cloud.run('isAdmin'); 

      // if user is not admin, recheck user type against database
      if (!isAdmin && Parse.User.current()) {
        await Parse.User.current().fetch();
        await checkUserPlans();
      }
    } catch (error) {
      if (error.code === Parse.Error.INVALID_SESSION_TOKEN) {
        alert("Your session has expired. Please log in again to continue.");
        Parse.User.logOut();
        if (userIsAdmin) {
          history.push('/phinaz');
        } else {
          history.push('/login');
        }
      } else {
        console.log('Fail to check membership information', error);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { resetImportState, location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      
      if(Parse.User.current() && !location.pathname.includes('signup')) {
        this.checkInvalidToken();
      }
      //close all tooltips
      $('[role="tooltip"]').hide();
    }

    if (prevProps.resetImportState !== resetImportState) {
      this.clearCsvImport();
    }
  }
  
  async componentWillUnmount() {
    window.removeEventListener(
      "beforeunload",
      this.saveStateToSessionStorage.bind(this)
    );

    // state persistence
    await this.saveStateToSessionStorage();
  }

  saveStateToSessionStorage = async () => {
    if(Parse.User.current()){
      for (const key in this.state) {
        try {
          sessionStorage.setItem(key, JSON.stringify(this.state[key]), serializer(null, 2));
        }
        catch (error) {
          // fail to save the key to sessionStorage
          // ignore error to prevent TypeError: Converting circular structure to JSON
        }
      }
    }
  }

  hydrateStateWithSessionStorage = async () => {
    try {
      for (const key in this.state) {
        if (sessionStorage.hasOwnProperty(key)) {
          let value = sessionStorage.getItem(key);
          // parse the sessionStorage string and setState

          try {
            value = JSON.parse(value);
            await this.setState({ [key]: value });
          } catch (e) {
            // handle empty string
            await this.setState({ [key]: value });
          }
        }
      }
    } catch (error) {
      this.setState({ activePortfolio: {} });
    }
  }

  updateCsvPreview = (csvPreview) => {
    this.setState({ csvPreview });
  }

  clearCsvImport = () => {
    const csvPreview = {};
    this.setState({ csvPreview });
    sessionStorage.setItem('csvPreview', JSON.stringify(csvPreview));
  }

  showNotification = async (type, message) => {
    await this.setState({ notification: { type, message } });
    if (this.toastTimeout) {
      clearTimeout(this.toastTimeout);
    }
    this.toastTimeout = setTimeout(() => this.hideNotification(), TOAST_DELAY);
  }

  hideNotification = () => {
    this.setState({ notification: null });
  }

  render() {
    const { setProgressPercentage, notifications, notificationsSeen, setNotificationStatus, navPadding,
      updateUserRole, userIsAdmin, updateUserInformation, userInfo,
      refreshNotifications, tagIds, productIds, onPremiumPlan, checkUserPlans, 
    } = this.props;
    
    const { csvPreview } = this.state;

    let adminRoute = null;

    if (userIsAdmin) {
      adminRoute = (
        <Fragment>
          <PrivateRoute path="/reports/:tabName?"
            RouteComponent={Reports}
            tagIds={tagIds} />
          <PrivateRoute path="/plans" 
            RouteComponent={Plans} />
        </Fragment>  
      );
    }

    return (
      <main style={navPadding ? {paddingTop: navPadding + "px"} : null }>
        <Switch>
          <Route path="/login" render={() => {
            if (Parse.User.current()) {
              return (
                <Home />
              )
            }
            return (
              <Signin updateUserRole={updateUserRole}  checkUserPlans={checkUserPlans} />
            )
          }} />
          <Route path="/signup/:page?" render={() => <Onboarding setProgressPercentage={setProgressPercentage} updateUserInformation={updateUserInformation} userInfo={userInfo} productIds={productIds} />} />
          <Route path="/resetpassword" render={() => <ResetPassword updateUserRole={updateUserRole} />} />
          <Route path="/choose-password" component={ChoosePassword}/>
          <Route path="/phinaz" render={() => <Signin adminPage={true} updateUserRole={updateUserRole} checkUserPlans={checkUserPlans} />} />
          <Route path="/invalid-link" component={NotFound}/>
          <Route path="/reset-password-success" component={RedirectFromResetPassword} />
          <Route from="/verify-email-success" component={RedirectFromVerifyEmail} />
          <Redirect from="/invalid-verify-link" to="/login?error=invalid-verify-link" />
          <PrivateRoute path="/ticker/:symbol"
            RouteComponent={TickerContent}
            showNavBar={true}
          />
          <PrivateRoute path="/account/:tabName"
            RouteComponent={Account}
            refreshNotifications={refreshNotifications}
            tagIds={tagIds}
            productIds={productIds}
            checkUserPlans={checkUserPlans}
            onPremiumPlan={onPremiumPlan} />
          <PrivateRoute path="/support" RouteComponent={Support} refreshNotifications={refreshNotifications} />
          <PrivateRoute path="/notifications" RouteComponent={Notifications} notifications={notifications} notificationsSeen={notificationsSeen} setNotificationStatus={setNotificationStatus} refreshNotifications={refreshNotifications} />
          <Route path="/portfolio/:subpath?" render={({ match, location }) => {
            if (Parse.User.current()) {
              if (match.params.subpath === 'TDH') {
                return <Redirect to="/portfolio" replace />;
              }
              return (
                <Portfolio
                  {...this.props}
                  updateCsvPreview={this.updateCsvPreview}
                />
              );
            }
            return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
          }} />
          <PrivateRoute path="/import-transactions"
            RouteComponent={ImportPage}
            csvPreview={csvPreview}
            clearCsvImport={this.clearCsvImport}
            showNotification={this.showNotification}
            userIsAdmin={userIsAdmin} />
          {adminRoute}
          <PrivateRoute path="/"
            RouteComponent={Home} exact
            refreshNotifications={refreshNotifications}
          />
          {/* 404 Page */}
          <Route path="*" component={NotFound} />
        </Switch>
      </main>
    );
  }
}

export default withRouter(Main);
